import { DirectiveBinding } from 'nuxt/dist/app/compat/capi';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('visibility', {
    beforeMount: (element: HTMLElement) => {
      element.style.opacity = '0';
    },
    updated: (element: HTMLElement, binding: DirectiveBinding<boolean>, node: VNode) => {
      if (!binding.value === !binding.oldValue || null === node.transition) {
        return;
      }
      if (!binding.value) {
        node.transition.leave(element, () => {
          element.style.opacity = '0';
        });
        return;
      }
      node.transition.beforeEnter(element);
      element.style.opacity = '';
      node.transition.enter(element);
    },
    getSSRProps: (binding) => {
      return {
        style: {
          opacity: binding.value ? '1' : '0',
        },
      };
    },
  });
});
