export default defineNuxtPlugin(async () => {
  return {
    provide: {
      branchObjectRepository: (await import('~/api/apollo/branchObject.repository')).branchObjectRepository(),
      carObjectRepository: (await import('~/api/apollo/carObject.repository')).carObjectRepository(),
      carModelRepository: (await import('~/api/apollo/carModel.repotitory')).carModelRepository(),
      productRepository: (await import('~/api/apollo/product.repository')).productRepository(),
      pageContentRepository: (await import('~/api/apollo/pageContent.repository')).pageContentRepository(),
      favoritesRepository: (await import('~/api/apollo/favorites.repository')).favoritesRepository(),
      siteContentRepository: (await import('~/api/apollo/siteContent.repository')).siteContentRepository(),
      siteSearchRepository: (await import('~/api/apollo/siteSearch.repository')).siteSearchRepository(),
      formRepository: (await import('~/api/apollo/form.repository')).formRepository(),
      subscriptionRepository: (await import('~/api/apollo/subscription.repository')).subscriptionRepository(),
      dynamicListRepository: (await import('~/api/apollo/dynamicList.repository')).dynamicListRepository(),
      registrationRepository: (await import('~/api/apollo/registration.repository')).registrationRepository(),
      cartRepository: (await import('~/api/apollo/cart.repository')).cartRepository(),
      checkoutRepository: (await import('~/api/apollo/checkout.repository')).checkoutRepository(),
      tireContentRepository: (await import('~/api/apollo/tireContent.repository')).tireContentRepository(),
      workshopBookingRepository: (await import('~/api/apollo/workshopBooking.repository')).workshopBookingRepository(),
    },
  };
});
