<template>
  <NuxtLayout :name="layout">
    <NuxtPage :use-fake-modal="onlyShowBody" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { LayoutKey } from '#build/types/layouts';
import { useSiteContentStore } from './stores/siteContent';
import { TrackJS } from 'trackjs';
import type { SiteContentQuery } from '~/types/generated-types';

const nuxtApp = useNuxtApp();
const config = useRuntimeConfig();
const route = useRoute();
const siteContent = useSiteContentStore();
const layout = ref<LayoutKey>('layout');

const handleError = (statusCode: number, message: string): void => {
  const error = { message: message, statusCode: statusCode };
  let isValidError = true;

  if (route.path.indexOf('_nuxt') >= 0) isValidError = false;
  if (route.path.endsWith('svg')) isValidError = false;

  if (isValidError) {
    if (import.meta.client) {
      showError(error);
    } else {
      throw createError(error);
    }
  }
};

const fetchSite = (): Promise<SiteContentQuery> => {
  return nuxtApp.$siteContentRepository.fetchSiteContent({
    host: siteContent.host,
    previewindex: config.public.previewIndex,
    siteId: config.public.siteId || '',
    indexPrefix: siteContent.indexPrefix ?? '',
    withNavigationPanel: config.public.withNavigationPanel,
  });
};

const { data: siteResponse } = await useAsyncData(
  'siteContent',
  async () => {
    const res = await fetchSite();

    if (res.siteContent && res.siteContent.resources) {
      siteContent.changeResources(res.siteContent.resources);
    }
    return res;
  },
  {
    transform: (res) => {
      // Remove resources from site content as to not include them in the payload. They are included in the pinia store.
      return {
        siteContent: {
          ...res.siteContent,
          resources: undefined,
        },
      };
    },
  }
);

if (siteResponse.value && siteResponse.value?.siteContent) {
  siteContent.changeSiteContent(siteResponse.value.siteContent as any);
} else {
  TrackJS.track('Site content is missing');
  handleError(500, 'Site content is missing');
}

// https://biliaab.atlassian.net/browse/MON-8602
// POC for only showing body content for lexus showroom touchscreens.
const pocTouchScreenFeatureFlag = ref((await nuxtApp.$getFeatureFlag('poctouchscreen')) ?? false);
const { systemText } = useSystemText();

const pocSystemText = systemText('pocLexusUrl');

const onlyShowBody = ref(pocTouchScreenFeatureFlag.value ? route.fullPath.includes(pocSystemText) ?? false : false);

if (pocTouchScreenFeatureFlag.value && config.public.lexusPoc) {
  if (route.fullPath.includes(pocSystemText)) {
    layout.value = 'poc-lexus';
  }

  watch(
    () => route.fullPath,
    () => {
      if (route.fullPath.includes(pocSystemText)) {
        onlyShowBody.value = true;
        layout.value = 'poc-lexus';
      } else {
        onlyShowBody.value = false;
        if (layout.value !== 'layout') layout.value = 'layout';
      }
    }
  );
}
</script>

<style lang="postcss">
@import './assets/scss/global.scss';

@media (prefers-reduced-motion) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}

::view-transition-group(*),
::view-transition-image-pair(*) {
  animation-timing-function: linear(
    0,
    0.005,
    0.02 2.3%,
    0.082 5.1%,
    0.463 16.8%,
    0.557,
    0.638,
    0.708,
    0.768 30%,
    0.819,
    0.862 37.4%,
    0.9 41.8%,
    0.931 46.7%,
    0.955 52.2%,
    0.972 58.5%,
    0.984 65.5%,
    0.992 74.1%,
    0.997 84.9%,
    0.999
  );
  animation-duration: 575ms;
}

::view-transition-old(*),
::view-transition-new(*) {
  animation-timing-function: ease;
  animation-duration: 0.45s;
}
</style>
