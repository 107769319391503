import mitt from 'mitt';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(() => {
  const emitter = mitt();

  return {
    // Using provide here allows Nuxt3 to generate typings
    provide: {
      bus: {
        $on: emitter.on,
        $emit: emitter.emit,
        $off: emitter.off,
      },
    },
  };
});
