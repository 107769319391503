import type { RouterConfig } from '@nuxt/schema';
// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior(to, _from, savedPosition) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ el: to.hash, top: 30 });
        } else if (savedPosition) {
          resolve({ top: savedPosition.top, left: savedPosition.left });
        } else if (to.path !== _from.path) {
          resolve({ top: 0, left: 0 });
        }
      }, 300);
    });
  },
};
