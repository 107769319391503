<template>
  <component :is="getDefinedComponent(siteTheme())" id="app">
    <header>
      <nav>
        <a href="/">
          <LazyGenericLogoSVG :name="'sitelogos/' + siteTheme()" :alt="siteTheme()" />
        </a>
      </nav>
    </header>
    <section class="error-section">
      <div class="upper-content">
        <div class="illustration">
          <svg width="100%" height="100%" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g fill="#192332" fill-rule="evenodd">
              <ellipse cx="256" cy="480" rx="173" ry="7" opacity="0.15" />
              <path
                d="M105.971 322.97c0-82.84 67.16-150 150-150s150 67.16 150 150c0 56.885-31.669 106.377-78.336 131.805-4.32 2.353-9.912 4.511-14.206 5.247-4.294.737-18.32 2.224-54.301 2.224s-59.248.267-76.511-8.407c-45.74-25.694-76.646-74.674-76.646-130.869zm345.5-2.5l4.47-.89c-.26-15.48-2.271-30.53-5.861-44.96l-4.609.29-1.29-4.82 4.149-2.05a198.317 198.317 0 00-17.33-41.9l-4.41 1.49-2.5-4.33 3.5-3.07a200.422 200.422 0 00-22.989-31.09l-20.179 2.258-17.238 28.832 7.262-33.38 11.378-8.46 1.255-5.22c-10.79-10.32-15.659-14.22-28.548-21.93l-3.201 3.64-4.33-2.5 1.55-4.58a198.02 198.02 0 00-42.069-17.3l-2.13 4.3-4.83-1.29.309-4.77a199.831 199.831 0 00-44.87-5.74h-.05l-.939 4.71h-5l-.94-4.69h-.05a199.3 199.3 0 00-44.881 5.97l.31 4.98-4.83 1.29-2.199-4.46a198.668 198.668 0 00-41.801 17.44l1.551 4.6-4.33 2.5-3.19-3.63a200.42 200.42 0 00-35.87 27.69l2.609 3.92-3.529 3.53-3.911-2.6a200.486 200.486 0 00-27.569 35.98l3.5 3.07-2.5 4.33-4.411-1.49a198.863 198.863 0 00-17.319 41.9l4.15 2.05-1.29 4.82-4.61-.29c-3.59 14.43-5.6 29.48-5.85 44.96l4.46.89v5l-4.471.89c.261 15.48 2.271 30.53 5.861 44.96l4.61-.3 1.29 4.83-4.15 2.05c4.2 14.73 10.04 28.76 17.33 41.89l4.4-1.49 2.5 4.33-3.491 3.07c3.94 6.6 8.27 12.951 12.94 19.02 5.341 6.96 8.37 12.87 10.571 17.79l3.069-1.28 2.801 4.14-3.231 3.26c.303.688.103.538 0 1.22-1.393 9.2 8.905 12.12 27.461 12.12H373.42c28.941 0 25.74-4.694 25.79-12.12l-.05.07-3.519-3.679 2.87-4.101 3.85 1.69c2.79-5.4 6.339-11.969 12.7-19.639a192.037 192.037 0 0012.62-18.401l-3.591-3.16 2.5-4.33 4.54 1.54a195.353 195.353 0 0017.29-41.89l-4.239-2.1 1.29-4.83 4.699.3c3.54-14.44 5.521-29.49 5.771-44.96l-4.47-.89v-5z"
              />
              <path
                d="M292.18 318.19a6.985 6.985 0 01-7.72-2.41 6.647 6.647 0 01-1.1-2.09c-1.2-3.67.81-7.62 4.49-8.82 3.68-1.19 7.63.82 8.82 4.5 0 .01.01.03.01.04 1.17 3.66-.84 7.58-4.5 8.78m-10.95 39.69c-3.13 2.28-7.5 1.58-9.78-1.55a6.99 6.99 0 011.55-9.77 6.953 6.953 0 017.04-.69c.56.24 1.08.57 1.56.97.01.01.01.02.02.02.43.36.82.78 1.16 1.25a6.993 6.993 0 01-1.55 9.77M256.25 294c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7m-15.2 62.33c-2.28 3.13-6.65 3.83-9.78 1.55a6.993 6.993 0 01-.41-11l.02-.02c.5-.42 1.05-.76 1.63-1.02 2.21-1 4.88-.81 6.99.72a6.992 6.992 0 011.55 9.77m-20.73-38.14c-3.66-1.2-5.67-5.13-4.5-8.79 0-.01.01-.02.01-.03 1.19-3.68 5.14-5.69 8.82-4.5 3.68 1.2 5.69 5.15 4.49 8.82a7.01 7.01 0 01-1.1 2.1 7 7 0 01-7.72 2.4m85.93 4.81c0-5.94-1.04-11.65-2.95-16.94 23.88-11.97 48.61-22.98 74.2-33.04l-17.34-30.03a881.26 881.26 0 01-65.71 47.77c-7.35-8.72-17.65-14.89-29.35-16.98 1.57-26.66 4.4-53.58 8.48-80.78h-34.67c4.09 26.98 6.93 53.9 8.5 80.78-11.7 2.09-22 8.25-29.36 16.97-22.29-14.7-44.2-30.62-65.72-47.76L135 273.02a883.367 883.367 0 0174.21 33.02 49.69 49.69 0 00-2.96 16.96c0 6 1.06 11.76 3.01 17.1A805.071 805.071 0 01135 372.99l17.33 30.03a837.974 837.974 0 0165.65-47.85c7.4 8.8 17.78 15.01 29.58 17.08a803.062 803.062 0 01-8.65 80.75h34.67c-4.19-26.88-7.06-53.8-8.61-80.76 11.8-2.07 22.18-8.29 29.57-17.1 22.84 14.97 44.71 30.93 65.62 47.88l17.34-30.03a835.753 835.753 0 01-74.25-32.92c1.94-5.33 3-11.08 3-17.07"
              />
              <path
                d="M256 188c74.558 0 135 60.442 135 135 0 74.558-60.442 135-135 135-74.558 0-135-60.442-135-135 0-74.558 60.442-135 135-135zm0 15c-66.274 0-120 53.726-120 120s53.726 120 120 120 120-53.726 120-120-53.726-120-120-120z"
              />
              <g transform="rotate(45 159.695 574.186)">
                <rect x="48.923" y=".165" width="6" height="98" rx="2.5" />
                <path
                  d="M99.742 45.143l1.352.634a2.5 2.5 0 011.207 3.315l-25.31 54.584a2.5 2.5 0 01-3.33 1.211l-1.351-.634a2.5 2.5 0 01-1.207-3.315l25.31-54.583a2.5 2.5 0 013.33-1.212zM3.224 45.07l1.352-.634a2.5 2.5 0 013.33 1.212l25.309 54.583a2.5 2.5 0 01-1.207 3.315l-1.352.634a2.5 2.5 0 01-3.329-1.212L2.017 48.385a2.5 2.5 0 011.207-3.315z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="error-information">
          <h1 class="title">
            Oops! <span class="error-code">{{ error.statusCode }}</span>
          </h1>
          <p class="message">
            {{ error.message }}
          </p>

          <ul v-if="atLeastOneSystemTextExists()" class="message-list">
            <li v-if="systemTextExists('ErrorPageTipText1')">{{ systemText('ErrorPageTipText1') }}</li>
            <li v-if="systemTextExists('ErrorPageTipText2')">{{ systemText('ErrorPageTipText2') }}</li>
            <li v-if="systemTextExists('ErrorPageTipText3')">{{ systemText('ErrorPageTipText3') }}</li>
          </ul>

          <NuxtLink to="/" class="back-button g-button g-button--large" @click="clearError()">
            <template v-if="systemTextExists('ErrorPageButtonText')">
              {{ systemText('ErrorPageButtonText') }}
            </template>
            <template v-else>Till startsidan</template>
          </NuxtLink>
        </div>
      </div>
    </section>
    <LazyFooter :site-name="siteTheme()" />
    <slot name="modal" />
  </component>
</template>

<script lang="ts" setup>
import { TrackJS } from 'trackjs';
import { getDefinedComponent } from './helpers/define.helper';
import { useSiteContentStore } from './stores/siteContent';
import type { NuxtError } from '@nuxt/types/app';
import type { SiteContentQuery, SiteContentResource } from './types/generated-types';

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: {} as NuxtError,
  },
});

const { systemText, systemTextExists } = useSystemText();
const config = useRuntimeConfig();
const siteContentStore = useSiteContentStore();
const nuxtApp = useNuxtApp();

const siteTheme = (): string => {
  return config?.public?.siteTheme ?? 'bilia';
};

const fetchSite = (): Promise<SiteContentQuery> => {
  return nuxtApp.$siteContentRepository.fetchSiteContent({
    host: siteContentStore.host,
    previewindex: config.public.previewIndex,
    siteId: config.public.siteId || '',
    indexPrefix: siteContentStore.indexPrefix ?? '',
    withNavigationPanel: config.public.withNavigationPanel,
  });
};

const { data: siteResponse } = await useAsyncData('siteContent', async () => {
  const res = await fetchSite();

  return res;
});

if (siteResponse.value) {
  siteContentStore.changeSiteContent(siteResponse.value.siteContent);
  siteContentStore.changeResources(siteResponse.value.siteContent?.resources as SiteContentResource[]);
}

const atLeastOneSystemTextExists = (): boolean => {
  return systemTextExists('ErrorPageTipText1') || systemTextExists('ErrorPageTipText2') || systemTextExists('ErrorPageTipText3');
};

TrackJS.track({
  statusCode: props.error.statusCode,
  message: props.error.message,
  path: props.error.path,
});
</script>

<style lang="postcss" scoped>
@import './assets/scss/global.scss';
@import '../motorit-component-library/src/assets/css/_mediaqueries.css';

header {
  display: grid;
  grid-template-columns: [full-start] 1fr [page-start] 1rem [main-start] minmax(0, var(--page-grid)) [main-end] 1rem [page-end] 1fr [full-end];
  top: 0;
  z-index: 100;
  background-color: var(--header-bg-color);
  -webkit-box-shadow: 0 2px 10px 0 var(--color-shadow);
  box-shadow: 0 2px 10px 0 var(--color-shadow);
}

nav {
  grid-column: main;
}

nav a {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.logo img {
  height: var(--logo-height, 2.5rem);
  max-width: 6rem;
  width: auto;
}

.error-section {
  display: flex;
  flex-direction: column;
}

.illustration {
  height: 10rem;
  width: 10rem;
  margin: -1rem 0 0;
  align-self: center;
}

.back-button {
  font-size: var(--font-size-0);
  margin-top: 1rem;
  width: 100%;
}

.title {
  align-self: start;
  font-size: var(--font-size-7);
}

h1 {
  span.error-code {
    font-size: var(--font-size-7);
    opacity: 0.1;
  }
}

.error-message-button.button.ghost {
  margin: 0 0 5rem;
  border-style: none;
}

.message {
  align-self: start;
  font-size: var(--font-size-3);
  font-weight: bold;
  margin: 0;
}

ul.message-list {
  padding: 0 0 0 1rem;
  width: 100%;

  li {
    padding-bottom: 0.25rem;
  }
}

.upper-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);
}

.error-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

@media (--medium) {
  .upper-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .illustration {
    height: 16rem;
    width: 16rem;
    margin: 1rem;
  }

  .upper-content > div {
    margin-top: -2rem;
  }

  .error-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4rem;
  }

  .back-button {
    align-self: start;
    width: fit-content;
  }
}
</style>
