import { useRouter } from '#app/composables/router';
import { defineNuxtPlugin } from '#app/nuxt';
export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    if (document) {
      if (
        (!document.startViewTransition || nuxtApp.payload.error?.statusCode) &&
        navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome')
      ) {
        return;
      }

      let finishTransition: undefined | (() => void);
      const router = useRouter();

      router.beforeResolve(async (to, from) => {
        if ((Object.values(to.query).length > 0 || Object.values(from.query).length > 0) && to.path === from.path) {
          return;
        }

        if (to.meta.pageTransition === false || to.hash || from.hash) {
          return;
        }

        await nuxtApp.callHook('page:transition:start');

        const promise = new Promise<void>((resolve) => {
          finishTransition = resolve;
        });

        let changeRoute: () => void;
        const ready = new Promise<void>((resolve) => (changeRoute = resolve));

        const transition = document?.startViewTransition?.(() => {
          changeRoute();
          return promise;
        });
        transition?.finished.then(() => nuxtApp.callHook('page:transition:finish'));

        await ready;
      });

      nuxtApp.hook('page:finish', () => {
        finishTransition?.();
        finishTransition = undefined;
      });
    }
  }
});
