// import { datadogRum } from '@datadog/browser-rum';

export default defineNuxtPlugin((nuxtApp: any) => {
  // const config = useRuntimeConfig();
  // if (process.env.NODE_ENV === 'production' && config.public.indexPrefix === 'bilia_se_') {
  //   datadogRum.init({
  //     applicationId: config.public.datadogApplicationId,
  //     clientToken: config.public.datadogClientToken,
  //     site: config.public.datadogSite,
  //     service: config.public.datadogService,
  //     env: process.env.NODE_ENV,
  //     version: config.public.releaseVersion as string,
  //     sessionSampleRate: 0,
  //     sessionReplaySampleRate: 0, //Number(config.public?.datadogSampleRate ?? 100), // Used for session replays and heatmaps
  //     trackUserInteractions: false, // Heatmaps
  //     trackResources: true,
  //     trackLongTasks: true,
  //     defaultPrivacyLevel: 'mask-user-input',
  //   });
  //   // datadogRum.startSessionReplayRecording(); // Used for session replays
  // }
});
