import * as ConfigCat from 'configcat-js-ssr';
import short from 'short-uuid';
let configCatClient: ConfigCat.IConfigCatClient;
const sessionId = short.uuid();
async function getFeatureFlag(featureFlag: string, defaultValue = false): Promise<boolean> {
  if (configCatClient == undefined) {
    return defaultValue;
  }
  const userObject = { identifier: sessionId };
  return await configCatClient.getValueAsync(featureFlag, defaultValue, userObject);
}

async function getAllFeatureFlags(): Promise<object[]> {
  let flags: object[] = [];
  flags = await configCatClient
    .getAllValuesAsync()
    .then((flags) => {
      return flags;
    })
    .catch(() => {
      console.log('error getting flags');
      return [];
    });
  return flags;
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  try {
    const logger = ConfigCat.createConsoleLogger(ConfigCat.LogLevel.Error);
    configCatClient = ConfigCat.getClient(config.public.configcat, ConfigCat.PollingMode.AutoPoll, {
      dataGovernance: ConfigCat.DataGovernance.EuOnly,
      logger: logger,
    });
    // configCatClient.forceRefreshAsync();
  } catch (error: any) {
    TrackJS?.track('configcat error' + error);
    configCatClient = {} as ConfigCat.IConfigCatClient;
  }

  return {
    provide: {
      configCatClient,
      getFeatureFlag,
      getAllFeatureFlags,
    },
  };
});
